import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import FilterForm from 'src/components/shared/FilterForm';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMDialogListFilterFormValues } from './types';

type Props = {
  defaultValues: SGMDialogListFilterFormValues;
};
export default function SGMDialogLisFiltersView({
  defaultValues,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  return (
    <Modal open onClose={closeModal}>
      <FilterForm<SGMDialogListFilterFormValues>
        defaultValues={defaultValues}
        onSubmit={closeModal}
      >
        {({ control }) => (
          <Card sx={{ minWidth: 500 }}>
            <CardHeader title="Filters" />
            <CardContent>
              <Box mb={2}>
                <Alert severity="info">
                  Data is reliably available from{' '}
                  {new Date(
                    CommonDateStrings.sgmInfosAdded.dateTime
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}{' '}
                  onward.
                </Alert>
              </Box>

              <Stack spacing={2}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                  <ControllerField
                    control={control}
                    name="startMessagesCreatedAt"
                    fullWidth
                    label="Start Messages Created Date"
                    type="date"
                    inputProps={{
                      max: CommonDateStrings.sevenDaysFromNow.date,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="endMessagesCreatedAt"
                    fullWidth
                    label="End Messages Created Date"
                    type="date"
                    inputProps={{
                      max: CommonDateStrings.sevenDaysFromNow.date,
                    }}
                  />
                  {/*
                  <ControllerField
                    control={control}
                    name="gpt4oMin"
                    fullWidth
                    label="Min gpt4o messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="gpt4oMax"
                    fullWidth
                    label="Max gpt4o messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="o1PreviewMin"
                    fullWidth
                    label="Min o1Preview messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="o1PreviewMax"
                    fullWidth
                    label="Max o1-Preview messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  */}
                  <ControllerField
                    control={control}
                    name="totalUserMessagesMin"
                    fullWidth
                    label="Min Total User Messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="totalUserMessagesMax"
                    fullWidth
                    label="Max Total User Messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="totalCountMin"
                    fullWidth
                    label="Min Total Count"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="totalCountMax"
                    fullWidth
                    label="Max Total Count"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <Controller
                    control={control}
                    name="published"
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Only Published"
                      />
                    )}
                  />
                </Box>
                <Flex justifyContent="space-between">
                  <Button color="error" variant="text" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button color="secondary" type="submit" variant="contained">
                    Filter
                  </Button>
                </Flex>
              </Stack>
            </CardContent>
          </Card>
        )}
      </FilterForm>
    </Modal>
  );
}
