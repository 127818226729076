import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SGMDialogListFilterFormValues } from './types';

export default function useDeliveryFiltersView() {
  const { allSearchParams } = useURLSearchParams();

  const defaultValues: SGMDialogListFilterFormValues = {
    startMessagesCreatedAt: allSearchParams.startMessagesCreatedAt ?? undefined,
    endMessagesCreatedAt: allSearchParams.endMessagesCreatedAt ?? undefined,
    /*
    gpt4oMin: allSearchParams.gpt4oMin
      ? parseInt(allSearchParams.gpt4oMin, 10)
      : 0,
    gpt4oMax: allSearchParams.gpt4oMax
      ? parseInt(allSearchParams.gpt4oMax, 10)
      : undefined,
    o1PreviewMin: allSearchParams.o1PreviewMin
      ? parseInt(allSearchParams.o1PreviewMin, 10)
      : 0,
    o1PreviewMax: allSearchParams.o1PreviewMax
      ? parseInt(allSearchParams.o1PreviewMax, 10)
      : undefined,
    */
    totalCountMin: allSearchParams.totalCountMin
      ? parseInt(allSearchParams.totalCountMin, 10)
      : 0,
    totalCountMax: allSearchParams.totalCountMax
      ? parseInt(allSearchParams.totalCountMax, 10)
      : undefined,
    totalUserMessagesMin: allSearchParams.totalUserMessagesMin
      ? parseInt(allSearchParams.totalUserMessagesMin, 10)
      : 0,
    totalUserMessagesMax: allSearchParams.totalUserMessagesMax
      ? parseInt(allSearchParams.totalUserMessagesMax, 10)
      : undefined,
    published: allSearchParams.published === 'true',
  };

  return {
    defaultValues,
  };
}
