import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Avatar, MenuItem, Stack } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SGMChatWithAssets } from 'src/lib/types/sgmChatMessage';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { FetchMakerDialogsQueryParams } from '../../view/List/useView';
import useMakerDialogsTable from './useView';

type MakerDialogsTableProps = {
  data?: SGMChatWithAssets[];
  totalCount: number;
  isLoading: boolean;
  params: FetchMakerDialogsQueryParams;
};

export default function MakerDialogsTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  totalCount,
}: MakerDialogsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  const { handleOpenMakerDialog } = useMakerDialogsTable();

  return (
    <MagicTable.Container>
      <MagicTable
        getRowId={(row) => row.id}
        rowHeight={64}
        loading={isLoading}
        pageSize={size}
        page={page}
        paginationMode="server"
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        onSortModelChange={(model) => {
          const currentModel = model[0];
          if (currentModel) {
            addParam('orderBy', currentModel.field);
            if (currentModel.sort) addParam('order', currentModel.sort);
          } else {
            removeParam('order');
            removeParam('orderBy');
          }
        }}
        sortModel={[
          {
            field: orderBy,
            sort: sortOrder as GridSortDirection,
          },
        ]}
        onCellDoubleClick={({ row }) => {
          if (row?.messagesInfo?.totalCount) handleOpenMakerDialog(row.id);
        }}
        rows={data ?? []}
        rowCount={totalCount}
        columns={[
          {
            field: 'title',
            headerName: 'Title',
            sortable: false,
            flex: 1,
            renderCell({ row }) {
              const hasMessages = (row?.messagesInfo?.totalCount ?? 0) > 0;
              return (
                <Flex component="span" gap={2}>
                  <Avatar
                    alt={row?.metadata?.title ?? 'user profile picture'}
                    src={row?.metadata?.icon ?? ''}
                  />
                  <Link
                    to={hasMessages ? Routes.sgm.dialog(row.id) : ''}
                    color={hasMessages ? 'inherit' : 'GrayText'}
                    underline="hover"
                  >
                    {row?.metadata?.title ?? '--'}
                  </Link>
                </Flex>
              );
            },
          },
          {
            field: 'user',
            headerName: 'User',
            sortable: false,
            renderCell({ row }) {
              return <MagicTableUserCell user={row.user} />;
            },
            flex: 1,
          },
          {
            field: 'gameUuid',
            headerName: 'Published',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => (
              <Stack direction={'row'}>
                <Label color={row.gameUuid ? 'success' : 'error'}>
                  {row.gameUuid ? 'Yes' : 'No'}
                </Label>
                {row.gameUuid && row.game && (
                  <Link to={Routes.game_center.game.view(row.gameUuid)}>
                    <OpenInNew />
                  </Link>
                )}
              </Stack>
            ),
          },
          {
            field: 'usedTemplate',
            headerName: 'Used Template',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => (
              <Stack direction={'row'}>
                <Label color={row.usedTemplate ? 'success' : 'error'}>
                  {row.usedTemplate ? 'Yes' : 'No'}
                </Label>
              </Stack>
            ),
          },
          {
            field: 'totalCount',
            headerName: '# Messages',
            sortable: true,
            flex: 0,
            renderCell: ({ row }) => row?.messagesInfo?.totalCount || '-',
          },
          {
            field: 'totalUserMessages',
            headerName: '# User',
            sortable: true,
            flex: 0,
            renderCell: ({ row }) =>
              row?.messagesInfo?.totalUserMessages || '-',
          },
          {
            field: 'c.updatedAt',
            headerName: 'Updated At',
            sortable: true,
            flex: 1,
            renderCell({ row }) {
              return row ? formatUTCDate(row.updatedAt) : '-';
            },
          },
          {
            field: 'c.createdAt',
            headerName: 'Created At',
            sortingOrder: ['desc', 'asc'],
            sortable: true,
            flex: 1,
            renderCell({ row }) {
              return row.createdAt ? formatUTCDate(row.createdAt) : '-';
            },
          },
          {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 10,
            flex: 0,
            renderCell({ row }) {
              return (
                <MoreMenu>
                  <CopyToClipboard text={row.id}>
                    <MenuItem aria-label="copy dialog uuid">
                      <ContentCopy />
                      Copy UUID
                    </MenuItem>
                  </CopyToClipboard>
                  {(row?.messagesInfo?.totalCount ?? 0) > 0 && (
                    <MenuItem
                      aria-label="view dialog"
                      onClick={() => {
                        handleOpenMakerDialog(row.id);
                      }}
                    >
                      <OpenInNew />
                      View Dialog
                    </MenuItem>
                  )}
                </MoreMenu>
              );
            },
          },
        ]}
      />
    </MagicTable.Container>
  );
}
