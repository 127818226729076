import { debounce } from 'lodash';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { fetchMakerDialogs } from '../../services/fetchMakerDialogs';
import SGMDialogListFilter from './Filters';

export default function useMakerDialogs(userAuthId?: string) {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  const openModal = useModalStore((state) => state.openModal);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,

    searchBy: allSearchParams.searchBy ?? 'c.title',
    query: allSearchParams.query ?? '',

    startMessagesCreatedAt: allSearchParams.startMessagesCreatedAt ?? '',
    endMessagesCreatedAt: allSearchParams.endMessagesCreatedAt ?? '',
    /*
    gpt4oMin: parseInt(allSearchParams.gpt4oMin ?? initialParams.gpt4oMin, 10),
    gpt4oMax: parseInt(allSearchParams.gpt4oMax ?? initialParams.gpt4oMax, 10),
    o1PreviewMin: parseInt(
      allSearchParams.o1PreviewMin ?? initialParams.o1PreviewMin,
      10
    ),
    o1PreviewMax: parseInt(
      allSearchParams.o1PreviewMax ?? initialParams.o1PreviewMax,
      10
    ),
    */
    totalCountMin: parseInt(
      allSearchParams.totalCountMin ?? initialParams.totalCountMin,
      10
    ),
    totalCountMax: parseInt(
      allSearchParams.totalCountMax ?? initialParams.totalCountMax,
      10
    ),
    totalUserMessagesMin: parseInt(
      allSearchParams.totalUserMessagesMin ??
        initialParams.totalUserMessagesMin,
      10
    ),
    totalUserMessagesMax: parseInt(
      allSearchParams.totalUserMessagesMax ??
        initialParams.totalUserMessagesMax,
      10
    ),
    published: allSearchParams.published === 'true',
  };

  const { data, isLoading } = fetchMakerDialogs({
    limit: params.size,
    offset: params.page * params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      'c.createdAt': {
        ...(params.startMessagesCreatedAt && {
          gte: `${params.startMessagesCreatedAt}T00:00:00.000Z`,
        }),
        ...(params.endMessagesCreatedAt && {
          lte: `${params.endMessagesCreatedAt}T23:59:59.999Z`,
        }),
      },
      /*
      gpt_4o: {
        ...(params.gpt4oMin && { gte: params.gpt4oMin }),
        ...(params.gpt4oMax && { lte: params.gpt4oMax }),
      },
      o1_preview: {
        ...(params.o1PreviewMin && { gte: params.o1PreviewMin }),
        ...(params.o1PreviewMax && { lte: params.o1PreviewMax }),
      },
       */
      totalCount: {
        ...(params.totalCountMin && { gte: params.totalCountMin }),
        ...(params.totalCountMax && { lte: params.totalCountMax }),
      },
      totalUserMessages: {
        ...(params.totalUserMessagesMin && {
          gte: params.totalUserMessagesMin,
        }),
        ...(params.totalUserMessagesMax && {
          lte: params.totalUserMessagesMax,
        }),
      },
      ...(params.published && {
        published: params.published,
      }),
      ...(userAuthId && {
        userUuid: userAuthId,
      }),
      ...(params.query &&
        params.searchBy == 'c.title' && {
          'c.title': `%${params.query}%`,
        }),
      ...(params.query &&
        params.searchBy == 'userUuid' && {
          userUuid: params.query,
        }),
    },
  });

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  async function handleOpenFilters() {
    openModal(<SGMDialogListFilter />);
  }

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return {
    data,
    isLoading,
    params,
    debouncedChangeField,
    addParam,
    removeParam,
    handleOpenFilters,
    debouncedSearch,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'c.createdAt',

  startMessagesCreatedAt: '',
  endMessagesCreatedAt: '',
  /*
  gpt4oMin: '',
  gpt4oMax: '',
  o1PreviewMin: '',
  o1PreviewMax: '',
  */
  totalCountMin: '',
  totalCountMax: '',
  totalUserMessagesMin: '',
  totalUserMessagesMax: '',
  published: '',
};

export type FetchMakerDialogsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;

  searchBy: string;
  query: string;

  startMessagesCreatedAt: string;
  endMessagesCreatedAt: string;
};
